import merge from 'webpack-merge'

export default {
  watch: {
    tabName: {
      immediate: true,
      handler(newVal, oldVal) {
        this.$router.push({
          query: merge(this.$route.query, { moduleName: newVal })
        })
      }
    }
  }
}
