<template>
  <div v-if="!blankData.isShow" :class="['errorPage', `errorPage--${blankData.type}`]">
    <img class="img" :src="imgUrl" />
    <div v-html="text"></div>
  </div>
  <div v-else class="notdata">
    <img class="img" :src="imgUrl" />
    <div class="errorPage_text">{{ text }}</div>
    <slot>
      <div class="errorPage-desc">
        <span>小贴士：</span>
        <span>会议决议指会议中形成的重要任务安排，指定责任人及完成日期，责任人需要在此更新任务状态，以便同步所有参会人员</span>
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Erroroption',
  data() {
    return {
      imgUrl: '',
      text: ''
    }
  },
  props: {
    blankData: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
    let text = ''
    let type = ''
    switch (this.blankData.type) {
      case 'behaviour-notdata':
        text = `<div class="errorPage_text">尚无扣分记录，请继续保持！</div>
                <p class="errorPage-desc">加载你过往所有的行为规范扣分记录</p>`
        type = 'behaviour-notdata'
        break
      case 'notdata':
        text = '<div class="errorPage_text">呀！这里是空的</div>'
        type = 300
        break
      case 'notApprove':
        text = '<div class="errorPage_text">暂无审批记录</div>'
        type = 301
        break
      case 'notEvaluate':
        text = '<div class="errorPage_text">暂无待评价人员</div>'
        type = 301
        break
      case 404:
        text = '<div class="errorPage_text">抱歉，您要找的页面不见啦</div>'
        type = 404
        break
      case 500:
        text = '<div class="errorPage_text">网络情况不太好，轻触屏幕重新加载</div>'
        type = 500
        break
      case 'nopermission':
        text = '<div class="errorPage_text">咦，好像权限不足 :(</div>'
        type = 300
        break
      case 'noAnnualTarget':
        text = '暂无指派给你的年度目标'
        type = 300
        break
      case 'noSubTarget':
        text = '暂未指派下级目标'
        type = 300
        break
      case 'noProcess':
        text = '<div class="errorPage_text">尚未设置考评流程</div>'
        type = 300
    }
    this.text = text
    this.imgUrl = require('../../../assets/images/' + type + '.png')
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss">
.errorPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: $white;
  .img {
    display: inline-block;
    width: 30%;
    margin-bottom: 20px;
  }
  .errorPage_text {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    text-align: center;
  }
  .errorPage-desc {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin-top: 15px;
  }
  &--behaviour-notdata {
    img {
      width: 100px !important;
    }
  }
}
.notdata {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  .errorPage_text {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
  .img {
    display: inline-block;
    width: 30%;
    margin-bottom: 20px;
  }
  .errorPage-desc {
    margin-top: 10%;
    width: 30%;
    span:first-child {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
      padding-bottom: 10px;
    }
    span {
      display: block;
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
}
</style>
