<script lang="jsx">
export default {
  name: 'tojoy-tabs',
  functional: true,
  render(h, {props, data, slots}) {
    const _tabs = props.tabs
    const _props = {
      ...props,
    }
    data.staticClass = (data.staticClass || '') + ' tojoy-tabs'

    return (
      <el-tabs {...{...data, props: _props}}>
      {
        _tabs.map((item, index) => {
          return (
            <el-tab-pane key={index} label={item.label} name={item.name}>
              <div slot="label" class="tojoy-tabs-wrap">
                <span>
                  { item.label }
                  {
                    item.badge ? (<i class='icon-red'></i>) : ''
                  }
                </span>
              </div>
            </el-tab-pane>
          )
        })
      }
      </el-tabs>)
  },
}
</script>

<style lang="scss">
.el-tabs {
  margin-top: 2px;
  &__header {
    margin: 0px;
  }
  &__item {
    padding-right: 20px !important;
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    height: 50px;
    line-height: 50px;
    &.is-active {
      color: $lightblack;
    }
  }
  &__active-bar {
    // left: 7%;
    // width: 30px !important;
  }
  &__nav-wrap::after {
    display: none;
  }
}
.tojoy-tabs {
  &-wrap {
    position: relative;
  }
  .icon-red {
    position: absolute;
    // top: 14px;
    top: -10px;
    right: -2px;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50px;
    background: $red;
  }

  .el-tabs__nav {
    //padding-bottom: 11px;
    min-height: 50px;
    display: flex;
    align-items: center;
  }
  .el-tabs__item {
    height: 0;
    line-height: 0;
  }
}
</style>
