<script lang="jsx">
import TojoyBlank from '@/components/business/blank/blank'

export default {
  name: 'tojoy-pagination-list',
  components: {
    TojoyBlank
  },
  props: {
    total: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 10,
    },
    data: {
      type: Array,
      default: () => []
    },
    schema: {
      type: String,
      default: 'line' // line | block
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    loading: {
      type: Boolean,
      default: false
    },
    nodataSchema: {
      type: String,
      default: 'notdata'
    },
    showRadius: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      scrollDom: {},
      currentPageVal: this.currentPage
    }
  },
  watch: {
    'currentPageVal': {
      immediate: true,
      handler(val) {
        if(this.scrollDom) {
          this.scrollDom.scrollTop = 0
        }
      }
    }
  },
  async updated() {
    await this.$nextTick()
    this.scrollDom = this.$refs['tojoy-pagination-wrap-ref']
  },
  methods: {
    handleChange(val) {
      this.currentPageVal = val
      this.resetScrollTop()
      this.$emit('page-change', val)
    },
    resetScrollTop() {
      if (this.scrollDom) {
        this.scrollDom.scrollTop = 0
      }
    }
  },
  
  render(h) {
    const {data, total, size, $scopedSlots, schema, loading, nodataSchema, showRadius} = this
    const currentClassNames = [`tojoy-pagination-list tojoy-pagination-list--${schema}`]
    const currentWrapClassNames = [schema==='line' && showRadius ? `tojoy-pagination-list__wrap show-radius` :  `tojoy-pagination-list__wrap`]
   
    return <div class={currentClassNames}>
      {
        loading ? (
          <div class="tojoy-pagination-list__loading"></div>
        ) : (
          data.length ? (
            <div ref="tojoy-pagination-wrap-ref" class={currentWrapClassNames}>
            {
              data.map((item, index) => {
                return (
                  <div key={index} class="item-card">
                    {
                      $scopedSlots.item({
                        data: item
                      })
                    }
                  </div>
                )
              })
            }
        </div>) : <tojoy-blank key={nodataSchema} class="tojoy-pagination-list__notdata" blank-data={{type: nodataSchema}} />
        )
      }
      {
        total ? (
          <el-pagination
            current-page={this.currentPage}
            background
            layout="prev, pager, next"
            page-size={size}
            total={total}
            on-current-change={val => this.handleChange(val)} />
            ) : ''
      }
      
    </div>
  }
}
</script>
<style lang="scss">
.tojoy-pagination-list {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &--line {
  }
  &__wrap {
    flex: 1;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    // background: $white;
    border-radius: 5px;
    z-index: 1;
    // margin: 20px 30px 0;
    padding: 20px 30px 20px;
    .item {
      &-card {
        display: flex;
        background: $white;
        border-bottom: 1px solid $linecolor;
        padding: 15px 15px 0;
        flex-shrink: 0;
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
    &.show-radius {
      .item-card {
        &:first-of-type {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
  &--block {
    .tojoy-pagination-list__wrap {
      background: transparent;
    }
    .item {
      &-card {
        display: flex;
        border-bottom: none;
        background: $white;
        padding: 15px 15px 0;
        margin-bottom: 10px;
        border-radius: 5px;
        &:last-of-type {
          border-bottom: none;
        }
        &:hover {
          transition: 0.1s;
          background: $hover-block;
        }
      }
    }
  }
  .el-pagination {
    z-index: 2;
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    background: $white;
    text-align: center;
    padding: 10px 0;
  }
  &__notdata {
    margin: 20px 30px;
    border-radius: 5px;
  }
  &__loading {
    margin: 20px 30px;
    background: $white;
    height: 100%;
    border-radius: 5px;
  }
}
</style>
