<template>
  <div class="title-content">
    <!-- <div class="content-top">
      <slot name="content"></slot>
    </div> -->
    <div class="content-bottom">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'title-content',
  props: {
    title: {
      type: String,
      default: ''
    },
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.title {
  &-content {
    box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
    background: $white;
    font-size: 18px;
    font-weight: bold;
    color: $lightblack;
    line-height: 18px;
    z-index: 2;
    // .content-top {
    //   min-height: 50px;
    //   display: flex;
    //   align-items: center;
    //   padding-left: 30px;
    //   padding-right: 30px;
    //   position: relative;
    // }
    .content-bottom {
      min-height: 50px;
      // display: flex;
      // align-items: center;
    }
  }
}
</style>
